import React from 'react';
import '../styles/biz-on-landing-header-2024-style.css';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
//import '../../../images/biz-on/biz-on-program-logo.webp';

function BizOnLandingHeader() {
  const { t, i18n } = useTranslation();

return (
  <>
    <section className="biz-on-new-header-ctn">
      <div className="rn-text-ctn container">
        <StaticImage
          src="../../../images/biz-on/BizOn_biale.svg"
          alt="Logo Biz-On"
          className="biz-on-program-logo"
          placeholder="biz-on-program-log"
          quality={100}
        />
        <h1>
          <span>{t('biz-on.about-title-main1')}</span>
          {i18n.language !== 'en' && (
            <span style={{ color: "#60a4c4" }}>{t('biz-on.about-title-main2')}</span>
          )}
        </h1>
        <h2 style={{ fontSize: "34px", textDecoration: "none", textTransform: "none" }}>{t('biz-on.about-title')}</h2>
        <p>{t('biz-on.about-paragraph')}</p>
        <Link style={{ color: "white" }} to="/registration" className="biz-on-btn-ctn btn-font">
          {t('biz-on.register')}
        </Link>
      </div>
      <div className="register-now-btn"></div>
    </section>
  </>
);

}

export default BizOnLandingHeader;
