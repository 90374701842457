import {
    AgendaSection,
    BIZONCard,
    BIZONCardCol,
    BIZONCardContainer,
    BIZONCardImg,
    BIZONCardRow,
    BIZONCardTitle,
    BIZONHollowCard,
    BIZONHollowCard2,
    BIZONHollowTitle,
    BIZONPreTitle,
    GainzSection,
    HeroLogo,
    HeroSubText,
    HollowText,
    ParnterSection,
    PatronLogo,
    SpeakerSection,
    WhereSection
  } from './styled.components';

import {
    Col,
    GradientWrapper,
    InnerColumn50,
    InnerColumn60,
    Row
  } from '../../../components/sections/styled.components';

import {
    Header2,
    Header3,
    PageHeader,
    SectionHeader,
    Text
  }
  from '../../../components/typography';

import {
    PageSection,
    PageWrapper,
    SectionWrapper
  } from '../../../components/global/flexbox';

import { Trans, useTranslation } from 'react-i18next';
import React from 'react';


export default function About() {
    const { t } = useTranslation('biz-on');
    return (
        <>
        <PageWrapper>
                <GainzSection
                    id="gain"
                    className="fsts"
                    style={{ marginTop: '-7rem', paddingBottom: '6rem', paddingTop: '6rem' }}
                    bg_col="#F5FAFD">

                    <SectionWrapper>
                        <Col>
                            <InnerColumn60 className="small-gap" style={{ alignItems: "center", maxWidth: "50%"}}>
                                <BIZONPreTitle>{t('biz-on-why-come')}</BIZONPreTitle>
                                <Header2 style={{color: "#060E15", fontSize: "36px"}}>{t('biz-on-gain')}</Header2>
                            </InnerColumn60>
                            <BIZONCardRow>
                                <BIZONHollowCard>
                                    <BIZONHollowTitle>
                                        {t('biz-on.gain-card1-title')}
                                    </BIZONHollowTitle>
                                    <HollowText>{t('biz-on-gain-card1-text')}</HollowText>
                                </BIZONHollowCard>
                                <BIZONHollowCard>
                                    <BIZONHollowTitle>
                                        {t('biz-on-gain-card2-title')}
                                    </BIZONHollowTitle>
                                    <HollowText>{t('biz-on-gain-card2-text')}</HollowText>
                                </BIZONHollowCard>
                                <BIZONHollowCard>
                                    <BIZONHollowTitle>
                                        {t('biz-on-gain-card3-title')}
                                    </BIZONHollowTitle>
                                    <HollowText>{t('biz-on-gain-card3-text')}</HollowText>
                                </BIZONHollowCard>
                            </BIZONCardRow>
                            <BIZONCardRow>
                                <BIZONHollowCard2>
                                    <BIZONHollowTitle>
                                        {t('biz-on-gain-card4-title')}
                                    </BIZONHollowTitle>
                                    <HollowText>{t('biz-on-gain-card4-text')}</HollowText>
                                </BIZONHollowCard2>
                                <BIZONHollowCard2>
                                    <BIZONHollowTitle>
                                        {t('biz-on-gain-card5-title')}
                                    </BIZONHollowTitle>
                                    <HollowText dangerouslySetInnerHTML={{ __html: t('biz-on-gain-card5-text') }}/>
                                </BIZONHollowCard2>
                            </BIZONCardRow>
                        </Col>
                    </SectionWrapper>
                </GainzSection>
            </PageWrapper>
        </>
    );
}