import Bubbles2 from '../../../images/home/theme-circles/circle-elements-mid.png';
import React, { useState } from 'react';
import { FstsGalleryImages } from '../../../mocks/fsts-gallery';
import { GalleryComponent } from '../../../components/galeria/index.old';
import { GalleryImageData } from '../../../mocks/gallery';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import ASTRA from '../../../images/logos/as.png';
import ClickMeeting from '../../../images/logos/14.png';
import LifeBe from '../../../images/logos/10.png';
import Heart from '../../../images/logos/11.png';
import FlyingBisons from '../../../images/logos/12.png';
import Havenocde from '../../../images/logos/13.png';
import CiC from '../../../images/logos/15.png';
import Forum from '../../../images/logos/forumbiz.png';
import SKNB from '../../../images/logos/sknb.png';
import {
    Col,
    GradientWrapper,
    InnerColumn50,
    InnerColumn60,
    Row
  } from '../../../components/sections/styled.components';
import {
    ParnterSection,
    PatronLogo,
    SpeakerSection,
    WhereSection
  } from './styled.components';


import {
    Header2,
    Header3,
    PageHeader,
    SectionHeader,
    Text
} from '../../../components/typography';

import {
    PageSection,
    PageWrapper,
    SectionWrapper
} from '../../../components/global/flexbox';

export default function PhotosPartner() {
    const { t, i18n } = useTranslation('biz-on');
    const currentLang = i18n.language;
    return (
        <>
            <PageSection style={{backgroundColor: "#F5FAFD"}}>
                <SectionWrapper>
                    <SectionHeader  style={{textAlign: "left", color: "#2C95B7", fontSize: "36px"}}>
                        <strong style= {{color: "#2C95B7"}}>{t('biz-on.gallery-header')}</strong>
                    </SectionHeader>
                    <Text style={{textAlign: "left", fontSize: "16px", color: "#060E15", fontWeight: "400"}}className="left">{t('biz-on.gallery-txt')}</Text>
                    <GalleryComponent content={GalleryImageData} />
                </SectionWrapper>
            </PageSection>


            <ParnterSection className="biz-on" bg_col="">
                <SectionWrapper>
                    <Col>
                    <Header2 className="partnerMobile" style= {{color: "#2C95B7", fontWeight: "600"}}>{t('biz-on.partners')}</Header2>
                    <Col>
                        <Header3 style= {{color: "#2C95B7"}}>{t('fsts-strategic')}</Header3>
                        <Row>
                        <PatronLogo
                            className="astra"
                            src={currentLang === 'pl' ? ASTRA : ASTRA}
                        />
                        <PatronLogo src={Forum} alt="Forum Logo" />
                        </Row>
                    </Col>
                    <Row className="space-between">
                        <Col>
                        <Header3 style= {{color: "#2C95B7"}}>{t('fsts-partners-eco')}</Header3>
                        <Row>
                            <PatronLogo className="uprp" src={ClickMeeting} alt="fClicMeeting Logo" />
                            <PatronLogo src={CiC} alt="Cic Logo" />
                        </Row>
                        </Col>
                    </Row>
                    <Col>
                        <Header3 style= {{color: "#2C95B7"}}>{t('fsts-financing')}</Header3>
                        <Row>
                        <PatronLogo src={Havenocde} alt="Havenocode Logo" />
                        <PatronLogo src={LifeBe} alt="LifeBe logo" />
                        <PatronLogo src={FlyingBisons} alt="flyingbisons Logo" />
                        <PatronLogo src={Heart} alt="The Hear Logo" />
                        </Row>
                    </Col>
                    <Col>
                        <Header3 style= {{color: "#2C95B7"}}>{t('fsts-coorganiser')}</Header3>
                        <Row>
                        <PatronLogo src={SKNB} alt="SKNB Logo" />
                        </Row>
                    </Col>
                    </Col>
                </SectionWrapper>
            </ParnterSection>    
        </>
    );
}
