import { graphql } from 'gatsby';
import BizOnPage from '../views/biz-on';

export default BizOnPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    amiData: contentfulStronaEventowa(contentful_id: {eq: "omxga6uC10JftV2NRGQyt"}) {
      speakers {
        name
        description {
          description
        }
        linkedin
        photo {
          title
          file {
            url
          }
        }
      }
    }
  }
`;
