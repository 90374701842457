import {
  BIZONCard,
  BIZONCardCol,
  BIZONCardContainer,
  BIZONCardImg,
  BIZONCardTitle,
  WhereSection
} from './styled.components';

import {
  Col,
  InnerColumn60,
} from '../../../components/sections/styled.components';

import {
  Header2,
  Text
}
  from '../../../components/typography';

import {
  SectionWrapper
} from '../../../components/global/flexbox';

import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import WHEN from '../../../images/biz-on/when.svg';
import WHERE from '../../../images/biz-on/where.svg';
import WHO from '../../../images/biz-on/who.svg';

import ClickMeeting from '../../../images/logos/14.png';
import Cic from '../../../images/logos/cic.png';
import '../styles/biz-on-landing-header-2024-style.css';
import '../biz-on.css';

export default function About() {
  const { t } = useTranslation('biz-on');
  return (
    <>
      <WhereSection
        className="fsts"
        id="investing">
        <SectionWrapper>
          <Col className="align-none">
            <InnerColumn60>
              <Header2 className="override-text-align biz-on-header-2-adjust" style={{ textAlign: "left" , fontSize: "36px", lineHeight: "54px", fontWeight: "600", color: "#090E14"}}>{t('biz-on.when-where-tile')}</Header2>
            </InnerColumn60>
            {/* <BIZONCardContainer style={{width: "100%", marginLeft: "15%"}}> */}
            <BIZONCardContainer>
              <BIZONCard style={{display: "flex", flex: "10 1"}}>
                <BIZONCardImg>
                  <img src={WHERE} alt="Ikona gdzie"/>
                </BIZONCardImg>
                <Col className="small-gap" style={{display: "inline" }}>
                  <BIZONCardTitle>{t('biz-on.where')}</BIZONCardTitle>

                  <Text  style={{color: "#2C95B7", paddingTop: "10px;"}}>{t('biz-on.where-txt')}</Text>
                  <Text style={{fontSize: "16px", color:"#090E14"}}>{t('biz-on.where-txt2')}</Text>
                  <img
                    src={ClickMeeting}
                    alt="ClickMeeting Logo"
                    style={{ paddingTop: '12px', paddingBottom: "20px", width: "50%", maxHeight: "50%"}}
                  />

                  <Text  style={{color: "#2C95B7", paddingTop: "10px;"}}>{t('biz-on.where-txt3')}</Text>
                  <Text style={{fontSize: "16px", color: "#090E14"}}>{t('biz-on.where-txt4')}</Text>
                  <img
                    src={Cic}
                    alt="Cic Logo"
                    style={{ paddingTop: '12px', width: "100%", maxHeight: "50%", marginLeft: "-20px"}}
                  />
                </Col>
              </BIZONCard>

              <BIZONCardCol style={{flexBasis: "45%"}}>
                <BIZONCard >
                  <BIZONCardImg>
                    <img src={WHO} alt="Ikona dla kogo"/>
                  </BIZONCardImg>
                  <Col className="small-gap">
                    <BIZONCardTitle>{t('biz-on.whom')}</BIZONCardTitle>
                    <Text style={{fontSize: "16px", color: "#090E14" }} >{t('biz-on.whom-txt')}</Text>
                  </Col>
                </BIZONCard>
                <BIZONCard style={{display: "flex", flex: "10 1"}}>
                  <BIZONCardImg>
                    <img src={WHEN} alt="Ikona kied"/>
                  </BIZONCardImg>
                  <Col className="small-gap">
                    <BIZONCardTitle>{t('biz-on.join')}</BIZONCardTitle>
                    <Text style={{fontSize: "16px", color:"#090E14"}} >{t('biz-on.join-txt')}</Text>
                    <Link style={{paddingLeft: "33px", paddingRight: "33px", paddingTop: "12px", paddingBottom: "12px"}}  to="/registration" className="biz-on-btn-ctn btn-font link-default link-responsive">
                      {t`biz-on.register`}
                    </Link>
                  </Col>
                </BIZONCard>
              </BIZONCardCol>

            </BIZONCardContainer>
          </Col>
        </SectionWrapper>
      </WhereSection>
    </>
  );
}