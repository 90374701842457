import {
  ComponentWrapper,
  GalleryInformationContainer,
  GalleryItemImage,
  GalleryItemWrapper,
  GallerySubtitle,
  GalleryTitle,
  GalleryWrapper
} from './styled.components';
import React, { useState } from 'react';

import { StyledBtn } from '../global/buttons/styled.components';
import { isWindow } from '../global/utils';
import { useTranslation } from 'react-i18next';

export function GalleryComponent({ content }) {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const [currentPage, setCurrentPage] = useState(1);
  const [showMoreVisibility, setShowMoreVisibility] = useState(true);

  let pageLenght = 6;
  const startIndex = 0;
  const endIndex = startIndex + pageLenght * currentPage;
  const totalPages = Math.ceil(content.length / pageLenght);
  let paginatedItems = content.slice(startIndex, endIndex);

  function loadMoreItems() {
    switch (true) {
      case currentPage < totalPages:
        setShowMoreVisibility(true);
        setCurrentPage((currentPage) => currentPage + 1);
        break;
      case currentPage === totalPages:
        setShowMoreVisibility(false);
      default:
        break;
    }
  }
  function goToFirstPage() {
    setCurrentPage(1);
    setShowMoreVisibility(true);
  }
  function openImageInNewCard(src) {
    if (isWindow) {
      window.open(window.location.origin + src, '_blank');
    }
  }

  return (
    <ComponentWrapper>
      <GalleryWrapper>
        {paginatedItems.map((galleryItem, index) => (
          <GalleryItemWrapper>
            <GalleryItem
              onClick={() => openImageInNewCard(galleryItem.src.default)}
              key={index}
              item={galleryItem.src}
            />
            <GalleryInformationContainer>
              {currentLang === 'pl' ? (
                <>
                  <GalleryTitle>{galleryItem.pl.title}</GalleryTitle>
                  <GallerySubtitle>{galleryItem.pl.subtitle}</GallerySubtitle>
                </>
              ) : (
                <>
                  <GalleryTitle>{galleryItem.en.title}</GalleryTitle>
                  <GallerySubtitle>{galleryItem.en.subtitle}</GallerySubtitle>
                </>
              )}
            </GalleryInformationContainer>
          </GalleryItemWrapper>
        ))}
      </GalleryWrapper>
      {!showMoreVisibility ? (
        <StyledBtn onClick={() => goToFirstPage()}>{t('hide')}</StyledBtn>
      ) : (
        <StyledBtn onClick={() => loadMoreItems()}>{t('show_more')}</StyledBtn>
      )}
    </ComponentWrapper>
  );
}

const GalleryItem = ({ item, onClick }) => {
  return (
    <GalleryItemWrapper onClick={onClick}>
      <GalleryItemImage src={item.default} alt={item?.alt} />
    </GalleryItemWrapper>
  );
};
