import styled from "styled-components";
import { respondTo } from "../../../utils/breakpoints";
import { PageSection } from "../../../components/global/flexbox";
import { Text } from "../../../components/typography";

import Bubble1 from '../src/dark-bubble-left.png';
import PartnerBubble2 from '../src/light-bubble-right.svg';
import Agenda from '../src/agenda.svg';
import Agenda2 from '../src/agenda2.svg';
import Where from '../src/where.svg'
import Gainz from '../../../images/biz-on/rectangle/Gainz1.svg'
import Gainz2 from '../../../images/biz-on/rectangle/Gainz2.svg'
import WhereLeft from '../../../images/biz-on/rectangle/WhereLeft.svg'
import WhereRight from '../../../images/biz-on/rectangle/WhereRight.svg'

import BubblesAbout from '../../../images/ami/bubble/about.svg';
import BubblesDetails from '../../../images/ami/bubble/details.svg';
import BubblesDetailsBlue from '../../../images/ami/bubble/details-blue.png';
import BubblesImportantInfo from '../../../images/ami/bubble/important-info.svg';
import BubblesPartners from '../../../images/ami/bubble/partner.svg';
import BubblesPartnersBlue from '../../../images/ami/bubble/partner-2.svg';
import BubblesPartnersGradient from '../../../images/ami/bubble/partner-3.svg';
import BubblesPromoted from '../../../images/ami/bubble/bee.svg';
import BubblesPromotedBlue from '../../../images/ami/bubble/bee-blue.svg';
import BubblesWorkshopGradient from '../../../images/ami/bubble/workshop-gradient.svg';
import BubblesWorkshopGradient2 from '../../../images/ami/bubble/workshop-gradient-2.svg';
import BubblesWorkshops from '../../../images/ami/bubble/workshops.svg';
import BubblesWorkshopsBlue from '../../../images/ami/bubble/workshop-purple.svg';
import HeroBg from "../../../assets/hero-bg.png";

import PolygonTopRight from '../../../images/biz-on/rectangle/Polygon 10.svg';
import PolygonMiddleRight from '../../../images/biz-on/rectangle/Polygon 11.svg';
import PolygonBottomRight from '../../../images/biz-on/rectangle/Polygon 12.svg';

import PolygonTopLeft from '../../../images/biz-on/rectangle/Polygon 7.svg';
import PolygonMiddleLeft from '../../../images/biz-on/rectangle/Polygon 8.svg';
import PolygonBottomLeft from '../../../images/biz-on/rectangle/Polygon 9.svg';

import PolygonLeftWorkshops from '../../../images/biz-on/rectangle/Group 319.svg';
import PolygonRightWorkshops from '../../../images/biz-on/rectangle/Group 321.svg';

import PartnerRightUpper from '../../../images/biz-on/rectangle/rightUpper.svg';
import PartnerRightBottom from '../../../images/biz-on/rectangle/rightBottom.svg';
import PartnerLeft from '../../../images/biz-on/rectangle/left.svg';




import Bubble2 from '../src/light-bubble.svg';


export const LogoGrid = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px 32px;
`

export const BIZONHollowCard = styled.div`
  display: flex;
  flex-basis: 100%;
  box-sizing: border-box;
  min-height: 260px;
  min-width: 31%;
  padding: 32px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  background-color: rgb(245, 250, 253);
  border-radius: 10px;
  ${respondTo.m`
    flex-basis: 100%;
    max-width:380px;
  `}
  border: 2px solid var(--primary-light, #2C95B733);
`
export const BIZONHollowCard2 = styled.div`
  display: flex;
  flex-basis: 100%;
  box-sizing: border-box;
  min-height: 260px;
  min-width: 48%;
  padding: 32px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  background-color: rgb(245, 250, 253);
  border-radius: 10px;
  ${respondTo.m`
    flex-basis: 1000%;
    max-width:380px;
  `}
  border: 2px solid var(--primary-light, #2C95B733);

  
`

export const BizOnCircleBorderedImage = styled.img`
  height: auto;
  width: 100%;
  ${respondTo.m`
    width: 479px;
    height:auto;
  `}
  &.promo-2 {
    width: 100%;
    height: auto;
    ${respondTo.m`
    width: 557.5px;
    height: 508.5px;  `}
  }
`;

export const BIZONHollowTitle = styled.h4`
  //styleName: Header/H4;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2C95B7;

`

export const HollowText = styled(Text)`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #060E15;

`

export const HeroLogo = styled.img`
  width: 250px;
`

export const HeroSubText = styled.span`
  color: var(--Bright-Light, #6EBDE9);

  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  letter-spacing: -0.36px;
`

export const EMSImg = styled.img`
  width: 100%;
`

export const LogoCard = styled.a`
  border-radius: 16px;
  width: 200px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid var(--base, #5FA5C5);
  background: #FFF;
  &:hover {
    background: rgba(95, 165, 197, 0.05);
  }
`;

export const LogoImage = styled.img`
  width: 140px;
  height: auto;
  aspect-ratio: 1/1;
`

export const PatronLogo = styled.img`
  width: 180px;
  &.astra {
    width: 100%;
    ${respondTo.m`
      width: 350px;
    `}
  }
  &.uprp {
    width: 240px;
    margin: 12px 0;
  }
  &.full {
    width: 100%;
    ${respondTo.m`
      width: 900px; 
    `}
  }
  height: auto;
  ${respondTo.xs`
    align-self: center;
  `}
`

export const BIZONCardTitle = styled.h4`
  
  margin-bottom: 5px;
  padding-top: 15px;
  color: #2C95B7;
  width: 100%;
  text-align: left !important;
  display: block;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29.26px;
  letter-spacing: -0.48px;
`

export const BIZONCardCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const BIZONCard = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 5px;
  background: #FFF;
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  box-shadow: 0px 0px 40px 0px rgba(18, 102, 140, 0.15);
  ${respondTo.m`
  flex-direction: row;
    width: 100%;
  `}
`

export const BIZONCardContainer = styled.div`
  display: flex;
  gap: 20px;
  box-sizing: border-box;
  flex-direction: column;
  > * {
    flex-basis: 50%;
  }
  ${respondTo.m`
    flex-direction: row;
  `}
`







export const CodeCard = styled.div`
  align-self: center;
  padding: 10px 24px;
  gap: 10px;
  font-size: bold;
  border-radius: 32px;
  background: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  letter-spacing: -0.36px;
  box-shadow: 0px 0px 40px 0px rgba(18, 102, 140, 0.15);
  > strong {
    color: #5FA5C5;
  }
`

export const StartupSection = styled(PageSection)`
  background-repeat: no-repeat;
  background-position: 
    top left, 
    center right, 
    bottom left;
`

export const ParnterSection = styled(PageSection)`
  background-color: ${(props) => props.bg_col || ''};
  background: url(${PartnerLeft}), url(${PartnerRightUpper}), url(${PartnerRightBottom});
  background-repeat: no-repeat;
  background-position-y: -40%, 5%, 90%;
  background-position-x: left, right, right;
  @media (max-width: 400px) {
    background-position-x: left, right, 160% !important;
  }
  ${respondTo.m`
    background-position-y: 30%, 10%, 90%;
  `}
`

export const BIZONPreTitle = styled.span`
  color: var(--base, #5FA5C5);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const BIZONCardRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`

export const BIZONCardImg = styled.div`
  padding: 16px;
`

export const SpeakerSection = styled(PageSection)`
  background: url(${Bubble1}), url(${Bubble2});
  background-color: ${(props) => props.bg_col || ''};
  background-repeat: no-repeat;
  background-position-x: right, left;
  background-position-y: 50%, 20%;
`

export const AgendaSection = styled(PageSection)`
  background-color: #F5FAFD !important;
  background: url(${Agenda}), url(${Agenda2});
  background-repeat: no-repeat;
  background-position-x: left, right;
  background-position-y: 90%, 5%;
  ${respondTo.m`
    background-position-y: 80%, 20%;
  `}
`

export const WhereSection = styled(PageSection)`

  background: url(${WhereLeft}), url(${WhereRight});
  background-repeat: no-repeat;
  background-color: ${(props) => props.bg_col || ''};
  background-repeat: no-repeat;
  background-position-x: right, left;
  background-position-y: 0%, 100%;
  padding-bottom: 80px;
  padding-top: 60px;
  @media (max-width: 400px) {
  background-position-x: 140%, -20%;
  }
`

export const GainzSection = styled(PageSection)`
  height: hug 839px;
  width: fill 1440px;
  background: url(${Gainz}), url(${Gainz2});
  background-color: #F5FAFD;
  background-repeat: no-repeat;
  background-position-x: right, left;
  background-position-y: 20%, 80%;
`

export const Header2 = styled.h2`
  color: var(--dark, #2C3E50);
  font-family: 'Montserrat';
  text-transform: none;
  text-align: left !important;
  font-size: 36px;
  margin-top: 0px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 54px */
  margin-bottom: 0px;
  letter-spacing: -0.72px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  > strong {
    color: var(--base, #5FA5C5);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
    letter-spacing: -0.48px;
  }
  &.white {
    color: white;
  }
`

export const BizOnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  &.custom-w {
    width: 100%;
    ${respondTo.m`
      width: ${(props) => props.customWidth || '100%'};
    `}
  }
  &.custom-h {
    height: 100%;
    ${respondTo.m`
      height: ${(props) => props.customHeight || '100%'};
    `}
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.gap-18 {
    ${respondTo.m`
      gap: 18px;
    `}
    }
  ${respondTo.m`
    flex-direction: column;
    gap: 48px;
    &.gap-24 {
      gap: 24px;
    }
  `}
`;

export const BizOnSection = styled.section`
  padding-top: 0;
  &.details {
    background: url(${BubblesDetails}) no-repeat right 10%, url(${BubblesDetailsBlue}) no-repeat left 50%;
    background-color: #F9F8FC;
  }
  &.important {
    background: url(${BubblesImportantInfo}) no-repeat right 10%;
  }
  &#hero {
    background-image: url(${HeroBg});
    background-size: cover;
    margin-top: 113px;
    padding: 62px 0px 0px 0px;
    ${respondTo.m`
      padding: 62px 0px 92px 0px;
    `}
  }
  &.about-new {
    background: url(${PolygonMiddleRight}) no-repeat  97% 88%, 
      url(${PolygonBottomRight}) no-repeat 98% 90%, 
      url(${PolygonTopRight}) no-repeat 98% 68%,
      url(${PolygonTopLeft}) no-repeat left 20%,
      url(${PolygonMiddleLeft}) no-repeat  2% 17%, 
      url(${PolygonBottomLeft}) no-repeat 2% 90%, 
      linear-gradient(180deg, #2D6D88 0%, #2091D0 100%);
  }
  &.promoted {
    background: linear-gradient(193deg, #4EBFEE 4.53%, #52368C 96.56%);
  }
  &.promoted-nest {
    width: 100%;
    height: 100%;
    background: url(${BubblesPromoted}) no-repeat right 70%, url(${BubblesPromotedBlue}) no-repeat left bottom;
    ${respondTo.m`
      background: url(${BubblesPromoted}) no-repeat right 20%, url(${BubblesPromotedBlue}) no-repeat left bottom; 
    `}
  }
  &.workshops {
    background: url(${PolygonRightWorkshops}) no-repeat right 20%, url(${PolygonLeftWorkshops}) no-repeat left 60%;
    ${respondTo.m`
      background: url(${PolygonRightWorkshops}) no-repeat right% 20%, url(${PolygonLeftWorkshops}) no-repeat left 60%;
    `}
  @media (max-width: 400px) {
      &.workshops {
        background: url(${PolygonRightWorkshops}) no-repeat 110% 20%, url(${PolygonLeftWorkshops}) no-repeat -30% 60%;
    }
  }
  &.partners {
    background: url(${BubblesPartnersBlue}) no-repeat right 20%, url(${BubblesPartnersGradient}) no-repeat right 70%;
    ${respondTo.m`
      background: url(${BubblesPartnersBlue}) no-repeat right 5%, url(${BubblesPartners}) no-repeat left 40%, url(${BubblesPartnersGradient}) no-repeat right 95%;
    `}
  }
  &.about {
    background: url(${PolygonMiddleRight}) no-repeat  97% 88%, 
      url(${PolygonBottomRight}) no-repeat 98% 90%, 
      url(${PolygonTopRight}) no-repeat 98% 68%,
      url(${PolygonTopLeft}) no-repeat left 20%,
      url(${PolygonMiddleLeft}) no-repeat  2% 17%, 
      url(${PolygonBottomLeft}) no-repeat 2% 90%, 
      linear-gradient(180deg, #2D6D88 0%, #2091D0 100%);
;
    ${respondTo.m`
      background: url(${PolygonMiddleRight}) no-repeat  97% 88%, 
      url(${PolygonBottomRight}) no-repeat 98% 90%, 
      url(${PolygonTopRight}) no-repeat 98% 68%,
      url(${PolygonTopLeft}) no-repeat left 20%,
      url(${PolygonMiddleLeft}) no-repeat  2% 17%, 
      url(${PolygonBottomLeft}) no-repeat 2% 90%, 
      linear-gradient(180deg, #2D6D88 0%, #2091D0 100%);

    `}

  }
`
export const HeaderContent = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
  ${respondTo.m`
  width: 680px;

  `}
  &.flex-start {
    justify-content: flex-start;
    justify-self: flex-start;
    align-self: flex-start;
    text-align: left !important;
    > h2 {
      text-align: left !important;
    }
  }
`

export const SpeakersWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px !important;
  width: 100%;
  flex-direction: column;
  ${respondTo.m`
    flex-wrap: wrap;
    flex-direction: row;
  `}
`
export const SpeakerItem = styled.div`
  width: 100%;
  ${respondTo.m`
    width: 182px;
  `}
`

export const Texts = styled.p`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 27.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #090E14

  &.purple {
    color: ${(props) => props.theme.colors.uiPurple};
  }
  &.bold {
    font-weight: 600 !important;
  }
  &.montserrat {
    font-family: 'Montserrat', sans-serif;
  }
  &.s-18 {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    
  }
  &.white {
    color: ${(props) => props.theme.colors.uiWhite};
  }
  &.s-24 {
    font-size: 18px;
    ${respondTo.m`
      font-size: 24px;
    `}
  }
  &.weight-500 {
    font-weight: 500 !important;
  }
  &.weight-300 {
    font-weight: 300 !important;
  }
`

export const BizOnSectionHeader = styled.h2`
  font-family: Montserrat;
  font-size: 36px !important;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.02em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-decoration: none;
  text-transform: none !important;
  color: ${(props) => props.theme.colors.uiBlack};
  &.grey {
    color: #2C3E50;
    font-weight: 600;
  }
  &.white {
    color: ${(props) => props.theme.colors.uiWhite};
  }
  & > strong {
    font-weight: 600;
    color: ${(props) => props.theme.colors.uiPurple};
  }
  &.text-left {
    text-align: left !important;
    margin: 0px;
  }
`

export const BizOnSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: 48px;
  ${respondTo.m`
    flex-direction: row;
    gap: 0px;
  `}
  &.column {
    flex-direction: column;
    gap: 33px;
  }
  &.p-60 {
    padding: 32px 0px;
    ${respondTo.m`
      padding: 60px 0px;
    `}
  }
  &.p-80 {
    padding: 48px 0px;
    ${respondTo.m`
      padding: 80px 0px;
    `}
  }
  &.gap-69 {
    gap: 69px;
  }
`