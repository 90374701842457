import {
    BizOnBootcamp,
    BizOnBootcampEN,
    BizOnWorkshops,
    BizOnWorkshopsEN
} from '../data/agenda';
import {
    BizOnSection,
    BizOnSectionContainer,
    BizOnSectionHeader,
    Text
} from './styled.components';

import CustomAgendaComponent from './CustomAgendaComponent';
import React from 'react';
import { SectionWrapper } from '../../../components/global/flexbox';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { respondTo } from '../../../utils/breakpoints';
import styled from 'styled-components';

export default function BizOnAgenda(props) {
    const { t, i18n } = useTranslation('ami');
    const [selectedAgenda, setSelectedAgenda] = useState(
      i18n.language === 'en' ? BizOnWorkshopsEN : BizOnWorkshops
    );
  
    const handleAgendaChange = (type) => {
      if (i18n.language === 'en') {
        setSelectedAgenda(type === BizOnWorkshops ? BizOnWorkshopsEN : BizOnBootcampEN);
      } else {
        setSelectedAgenda(type);
      }
    };
  
    return (
        <>
            <BizOnSection style={{backgroundColor: "#F5FAFD"}}>
                    <SectionWrapper>
                    <BizOnSectionContainer className="column p-60">
                        <BizOnSectionHeader style={{fontWeight: "600"}}>{t('biz-on.agenda')}</BizOnSectionHeader>
                        <AgendaBar>
                        {[BizOnWorkshops, BizOnBootcamp].map((agenda, index) => (
                            <AgendaButton
                            key={index}
                            selected={
                                selectedAgenda === agenda ||
                                selectedAgenda ===
                                (agenda === BizOnWorkshops ? BizOnWorkshopsEN : BizOnBootcampEN)
                            }
                            onClick={() => handleAgendaChange(agenda)}>
                            
                            {t(`agendaSection.${index}`)}
                            </AgendaButton>
                        ))}
                        </AgendaBar>
                        <Agenda>
                        {selectedAgenda.map((workshop, index) => (
                            <CustomAgendaComponent
                            key={index}
                            isDay={
                                selectedAgenda === BizOnWorkshops ||
                                selectedAgenda === BizOnWorkshopsEN
                            }
                            {...workshop}
                            />
                        ))}
                        </Agenda>
                    </BizOnSectionContainer>
                </SectionWrapper>
            </BizOnSection>
      </>
    );
}

  const Agenda = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
`;

const SpeakersWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px !important;
  width: 100%;
  flex-direction: column;
  ${respondTo.m`
    flex-wrap: wrap;
    flex-direction: row;
  `}
`;
const SpeakerItem = styled.div`
  width: 100%;
  ${respondTo.m`
    width: 182px;
  `}
`;

export const HeaderContent = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
  ${respondTo.m`
  width: 680px;

  `}
  &.flex-start {
    justify-content: flex-start;
    justify-self: flex-start;
    align-self: flex-start;
    text-align: left !important;
    > h2 {
      text-align: left !important;
    }
  }
`;

const AgendaTypes = {
  WORKSHOP: 'workshop',
  BOOTCAMP: 'bootcamp'
};

const AgendaBar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const AgendaButtonWrapper = styled.div`
  display: flex;
  border-radius: 8px;
`;

const AgendaButton = styled.button`
  padding: 10px 20px;
  font-size: 18px;
  font-family: 'Montserrat';
  min-height: 54px;
  max-height: 70px;
  border: none;
  cursor: pointer;
  &:nth-of-type(1) {
    border-radius: 8px 0 0 8px;
  }
  &:nth-of-type(2) {
    border-radius: 0 8px 8px 0;
  }
  background-color: ${({ selected }) => (selected ? '#2C95B7' : '#ffffff')};
  color: ${({ selected }) => (selected ? '#ffffff' : '#2C95B7')};
  border-radius: 4px;
  &:hover {
    background-color: ${({ selected }) => (selected ? '#2C95B7' : '#ffffff')};
  }
`;