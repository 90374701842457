const images = require.context('../images/biz-on/galeria', true);

const contentPL = [
  'Hackathon BizOn. Edycja 2024.',
  'Hackathon BizOn. Edycja 2024.',
  'Hackathon BizOn. Edycja 2024.',
  'Hackathon BizOn. Edycja 2024.',
  'Hackathon BizOn. Edycja 2024.',
  'Hackathon BizOn. Edycja 2024.',
  'Hackathon BizOn. Edycja 2024.',
  'Hackathon BizOn. Edycja 2024.',
  'Hackathon BizOn. Edycja 2024.'
];

const contentEN = [
  'Hackathon BizOn. 2024 Edition.',
  'Hackathon BizOn. 2024 Edition.',
  'Hackathon BizOn. 2024 Edition.',
  'Hackathon BizOn. 2024 Edition.',
  'Hackathon BizOn. 2024 Edition.',
  'Hackathon BizOn. 2024 Edition.',
  'Hackathon BizOn. 2024 Edition.',
  'Hackathon BizOn. 2024 Edition.',
  'Hackathon BizOn. 2024 Edition.',
  'Hackathon BizOn. 2024 Edition.',
  'Hackathon BizOn. 2024 Edition.',

];

//TODO: change this when gallery is properly implemented in contentful
export const GalleryImageData = images.keys()
  .sort((a, b) => {
    const numA = parseInt(a.match(/(\d+)/)[0], 10);
    const numB = parseInt(b.match(/(\d+)/)[0], 10);

    return numA - numB;
  })
  .map((image, index) => ({
    src: {
      default: images(image).default,
    },
    pl: {
      title: contentPL[index]?.split('.')[0],
      subtitle: contentPL[index]?.split('.').slice(1).join(' '),
    },
    en: {
      title: contentEN[index]?.split('.')[0],
      subtitle: contentEN[index]?.split('.').slice(1).join(' '),
    },
  }));
