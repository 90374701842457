export const SpeakerTitles =
  [
    'Prezes Fundacji InnovationsHub',
    'UX Design Manager, Flying Bisons',
    'UI/UX Designer, Flying Bisons',
    'Co-owner Alfa Lingua',
    'Founder LifeBe',
    'Specjalistka UX',
    'Co-Founder Havenocode',
    'Senior Consultant, The Heart',
    'Venture Architect',
    'Employer Brand and Recruitment Marketing Manager'
  ]
  