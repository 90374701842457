const BizOnWorkshops = [
    {
      name: "Discovery & Ideation",
      startTime: "2024-10-14T14:10",
      panelLength: "60",
      speakers: "Kacper Raciborski",
    },
    {
      name: "Proces projektowy produktu od A do Z",
      startTime: "2024-10-15T15:10",
      panelLength: "60",
      speakers: "Natalia Sidor, Łukasz Krótki (Flying Bisons)",
    },
    {
      name: "MVP w kilka godzin - szybkie prototypowanie aplikacji mobilnej",
      startTime: "2024-10-16T16:10",
      panelLength: "60",
      speakers: "Wojciech Gargul (LifeBe)",
    },
    {
      name: "Design Glow Up: Popraw błędy, projektuj świadomie",
      startTime: "2024-10-19T19:10",
      panelLength: "60",
      speakers: "Ewa Hechsman",
    },
    {
      name: "Wykorzystanie Bubble do szybkiego budowania aplikacji webowych",
      startTime: "2024-10-21T21:10",
      panelLength: "60",
      speakers: "Kamil Tarczyński (Havenocode)",
    },
    {
      name: "Od wyzwania do dostarczania wartości: Tworzenie i walidacja konceptów biznesowych",
      startTime: "2024-10-23T23:10",
      panelLength: "60",
      speakers: "Zuzanna Śniecińska (The Heart), Kacper Olczyk",

    },
    {
      name: "Marka osobista i budowa pozytywnego wizerunku jako wstęp do kariery w branży badań klinicznych",
      startTime: "2024-10-24T00:10",
      panelLength: "60",
      speakers: "Agata Qanadilo", // Placeholder if the speaker's name is unknown
    }
];
const BizOnBootcamp = [
    {
      name: 'Rejestracja Uczestników',
      startTime: '2023-09-25T14:30+02:00',
      panelLength: '30',
      icon: 'agenda'
    },
    {
      name: "Rozpoczęcie Finału Hackathonu, warsztaty z autoprezentacji",
      startTime: "2024-10-25T15:00",
      panelLength: "180",
      speakers: "Dawid Gościk",
    },
    {
      name: "Przerwa networkingowa, pizza",
      startTime: '2023-09-25T18:00+02:00',
      panelLength: '30',
      icon: 'pizza'
    },
    {
      name: "Ogłoszenie składów zespołów oraz wyzwania",
      startTime: '2023-09-25T18:30+02:00',
      panelLength: '15',
      icon: 'networking'
    },
    {
      name: "Praca nad wyzwaniem",
      startTime: "2024-10-25T18:45",
      panelLength: "180",
      icon: "laptop"
    },
    {
      name: "Nocna Praca nad wyzwaniem w CIC",
      startTime: "2024-10-25T22:00",
      panelLength: "600",
      icon: "laptop"
    },
    {
      name: "Praca nad wyzwaniem w Forum, konsultacje z mentorami",
      startTime: "2024-10-26T08:00",
      panelLength: "660",
      icon: "laptop"
    },
    {
      name: "Przerwa obiadowa",
      startTime: "2024-10-26T14:00",
      panelLength: "60",
      icon: "pizza"
    },
    {
      name: "Prezentacja prac przed jury",
      startTime: "2024-10-26T19:00",
      panelLength: "60",
      icon: "microphon"
    },
    {
      name: "Obrady Jury, rozdanie nagród",
      startTime: "2024-10-26T20:00",
      panelLength: "90",
      icon: "trophy"
    }
];


const BizOnWorkshopsEN = [
  {
    "name": "Discovery & Ideation",
    "startTime": "2024-10-14T14:10",
    "panelLength": "60",
    "speakers": "Kacper Raciborski"
  },
  {
    "name": "The Product Design Process from A to Z",
    "startTime": "2024-10-15T15:10",
    "panelLength": "60",
    "speakers": "Natalia Sidor, Łukasz Krótki (Flying Bisons)"
  },
  {
    "name": "MVP in a Few Hours - Quick Prototyping of a Mobile Application",
    "startTime": "2024-10-16T16:10",
    "panelLength": "60",
    "speakers": "Wojciech Gargul (LifeBe)"
  },
  {
    "name": "Design Glow Up: Design Consciously",
    "startTime": "2024-10-19T19:10",
    "panelLength": "60",
    "speakers": "Ewa Hechsman"
  },
  {
    "name": "Using Bubble to Quickly Develop Web Applications",
    "startTime": "2024-10-21T21:10",
    "panelLength": "60",
    "speakers": "Kamil Tarczyński (Havenocode)"
  },
  {
    "name": "From the Challenge to Delivering Value: Creating and Validating Business Concepts",
    "startTime": "2024-10-23T23:10",
    "panelLength": "60",
    "speakers": "Zuzanna Śniecińska (The Heart), Kacper Olczyk"
  },
  {
    "name": "Personal Branding and Building a Positive Image as an Introduction to a Career in Clinical Research",
    "startTime": "2024-10-24T00:10",
    "panelLength": "60",
    "speakers": "Agata Qanadilo"
  }
];
const BizOnBootcampEN = [
  {
    "name": "Registration of Participants",
    "startTime": "2023-09-25T14:30+02:00",
    "panelLength": "30",
    "icon": "agenda"
  },
  {
    "name": "Start of the Hackathon Final, Self-Presentation Workshop",
    "startTime": "2024-10-25T15:00",
    "panelLength": "180",
    "speakers": "Dawid Gościk"
  },
  {
    "name": "Networking Break, Pizza",
    "startTime": "2023-09-25T18:00+02:00",
    "panelLength": "30",
    "icon": "pizza"
  },
  {
    "name": "Announcement of the Teams and the Challenge",
    "startTime": "2023-09-25T18:30+02:00",
    "panelLength": "15",
    "icon": "networking"
  },
  {
    "name": "Working on the Challenge",
    "startTime": "2024-10-25T18:45",
    "panelLength": "180",
    "icon": "laptop"
  },
  {
    "name": "Night Work on the Challenge at the CIC",
    "startTime": "2024-10-25T22:00",
    "panelLength": "600",
    "icon": "laptop"
  },
  {
    "name": "Working on the Challenge in the Forum, Consultations with Mentors",
    "startTime": "2024-10-26T08:00",
    "panelLength": "660",
    "icon": "laptop"
  },
  {
    "name": "Lunch Break",
    "startTime": "2024-10-26T14:00",
    "panelLength": "60",
    "icon": "pizza"
  },
  {
    "name": "Presentation of the Work to the Jury",
    "startTime": "2024-10-26T19:00",
    "panelLength": "60",
    "icon": "microphon"
  },
  {
    "name": "Jury Deliberation, Prize Distribution",
    "startTime": "2024-10-26T20:00",
    "panelLength": "90",
    "icon": "trophy"
  }
];

export { BizOnWorkshops, BizOnBootcamp, BizOnWorkshopsEN, BizOnBootcampEN };