import React, { useState } from 'react';
import './biz-on.css';
import Seo from '../../components/seo';
import ogImage from '../../images/seo/SEO_MBIP.jpg';
import Layout from '../../components/layout/layout';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import BizOnLandingHeader from './components/biz-on-landing-header';
import BizOnWhenHeader from './components/biz-on-when-header';
import About from './components/About'
import Speakers from './components/speakers'
import Gainz from './components/gainz'
import Where from './components/where'
import BizOnAgenda from './components/agenda'
import PhotosPartner from './components/photos_partners'
import { theme } from '../../themes/default';
import { ThemeProvider } from 'styled-components';
import { alignCenter, isWindow } from '../../components/global/utils';





const BizOnNew = ({data}) => {
  /*const { location } = props;*/
  const { t, i18n } = useTranslation();
 
  
  const { locales, amiData } = data;

  const isMobile = isWindow() && window.innerWidth < 768;

  return (
    <ThemeProvider theme={theme}>
      <Layout withHeader={false}>
        <Seo 
          title={t`biz-on.page-title`}
          description={t`biz-on.page-description`}
          ogImage={ogImage}
        />

        <div className="biz-on-new-ctn">
          <BizOnLandingHeader />
        </div>
        <About />
        <Gainz />
        <BizOnWhenHeader />
        <Where />
        <BizOnAgenda />
        <Speakers {...amiData.speakers} />
        <PhotosPartner />
      </Layout>
    </ThemeProvider>
  );
};

export default BizOnNew;
