import React from 'react';
import '../styles/biz-on-when-header-style.css';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
//import '../../../images/biz-on/biz-on-when-program-logo.webp';

function BizOnWhenHeader() {
  const { t } = useTranslation();

  return (
    <>
      <section className="biz-on-when-new-header-ctn">
        <div className='centered'>
          <h1>{t`biz-on.when-title`}</h1>
          <p>{t`biz-on.when-txt`}</p>
        </div>
        <div className="register-now-btn"></div>
      </section>
    </>
  );
}

export default BizOnWhenHeader;
