import {
  BizOnCircleBorderedImage,
  BizOnContainer,
  BizOnSection,
  BizOnSectionContainer,
  BizOnSectionHeader,
  Texts
} from './styled.components';
import { Trans, useTranslation } from 'react-i18next';

import GrafikaPromo from '../../../images/biz-on/right.png';
import React from 'react';
import { SectionWrapper } from '../../../components/global/flexbox';

export default function About() {
  const { t } = useTranslation('biz-on');
  return (
    <>
      <BizOnSection className="what-is">
        <SectionWrapper>
          <BizOnSectionContainer className="p-60">
            <BizOnContainer customWidth="501px" customHeight="367px" className="gap-24 custom-w custom-h">
              <BizOnSectionHeader className="grey text-left">
                {t('biz-on.about_title')}
              </BizOnSectionHeader>
              <Texts className="about">{t('biz-on.about_text')}</Texts>
            </BizOnContainer>
            <BizOnCircleBorderedImage src={GrafikaPromo} alt="Promo" />
          </BizOnSectionContainer>
        </SectionWrapper>
      </BizOnSection>
      <BizOnSection className="about about-new">
        <SectionWrapper>
          <BizOnSectionContainer className="gap-69 p-60">
            <iframe
              style={{ maxWidth: '710px', width: '100%', borderRadius: "8px" }}
              height="400"
              src="https://www.youtube.com/embed/jm2fDnOzYeY"
              title={t('biz-on.relations')}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              reffererPolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
            <BizOnContainer className="gap-18 custom-w" customWidth="400px">
              <Texts className="white bold montserrat s-18">
                {t('biz-on.relations')}
              </Texts>
              <Texts className="white s-18">
                <Trans
                  i18nKey="biz-on.relationsv2"
                  components={{
                    joinbtn: (
                      <a
                        href="#"
                        rel="noreferrer"
                        style={{
                          color: '#ffffff',
                          textDecoration: 'underline'
                        }}
                      />
                    )
                  }}
                />
              </Texts>
            </BizOnContainer>
          </BizOnSectionContainer>
        </SectionWrapper>
      </BizOnSection>
    </>
  );
}
