
import {
    BizOnSection,
    BizOnSectionContainer,
    BizOnSectionHeader,
    HeaderContent,
    SpeakerItem,
    SpeakersWrapper,
    Texts
} from './styled.components';
import {
    Header2,
    Header3,
    PageHeader,
    SectionHeader,
    Text
}
from '../../../components/typography';



import CustomAgendaComponent from './CustomAgendaComponent';
import React from 'react';
import { SectionWrapper } from '../../../components/global/flexbox';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { respondTo } from '../../../utils/breakpoints';
import { Speaker } from '../../../templates/workshops/components/speakers';
import { SpeakerTitles } from '../data/speaker-titles';
import styled from 'styled-components';
export default function Speakers(props) {
    const { t, i18n } = useTranslation('biz-on');
    const speakers = Object.values(props);
    console.log("speakers", speakers);
    return (
      <>
        <BizOnSection className="workshops">
            <SectionWrapper>
                <BizOnSectionContainer className="column p-60">
                    <HeaderContent>
                      <Header2 style={{textAlign: "center", color: "#2b96b8", fontSize: "36px", lineHeight: "54px"}}>
                        {t('speakersSection.title')}
                      </Header2>
                      <Texts style={{textAlign: "center"}}className="text-center">{t('biz-on.speakersSection-text')}</Texts>
                    </HeaderContent>
                    <SpeakersWrapper>
                      {speakers.map((speaker, index) => (

                        <SpeakerItem key={speaker.name}>
                            
                          <Speaker
                            {...speaker}
                            noDescription
                            small
                            title={SpeakerTitles[index]}
                          />
                        </SpeakerItem>
                      ))}
                    </SpeakersWrapper>
                </BizOnSectionContainer>
            </SectionWrapper>
        </BizOnSection>
      </>
    );
  }
  