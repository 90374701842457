import React from 'react';
import moment from 'moment';
import { respondTo } from '../../../utils/breakpoints';
import styled from 'styled-components';
import { width } from '@fortawesome/free-brands-svg-icons/faInstagram';

export default function CustomAgendaComponent(props) {
  const time = props.isDay
    ? moment(props.startTime).format('DD.MM')
    : moment(props.startTime).format('HH:mm');
  
    const hours = Math.floor(props.panelLength / 60);
    const minutes = props.panelLength % 60;
    const formattedTime = 
      hours > 0 && minutes > 0 ? `${hours}h ${minutes}min` :
      hours > 0 ? `${hours}h` :
      `${minutes}min`;
  return (
    <AgendaBar>
      <AgendaTime>{time}</AgendaTime>
      <AgendaContentWrapper>
        <AgendaContent>
          <AgendaTitle>{props.name}</AgendaTitle>
          <AgendaSpeakers>{props.speakers}</AgendaSpeakers>
        </AgendaContent>
        {!props.isDay && (
          <AgendaDetails>
            {props.icon && (
              <AgendaIconWrapper style={{border: "none", height: "100%", width: "100%"}}>
                <AgendaIcon src={`/assets/biz-on/${props.icon}.svg`} />
              </AgendaIconWrapper>
            )}
            <Pill>
              <AgendaIcon src={'/assets/biz-on/ic.svg'} isClock={true} />
              {formattedTime}

            </Pill>
          </AgendaDetails>
        )}
      </AgendaContentWrapper>
    </AgendaBar>
  );
}

const AgendaBar = styled.div`
  border-radius: 8px;
  background-color: #fff;
  max-width: 1180px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: flex-start;
  box-shadow: 2px 2px 20px 0px rgba(82, 54, 140, 0.15);
`;

const AgendaTime = styled.div`
  display: flex;
  width: 80px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: 'Raleway';
  box-sizing: border-box;
  align-self: stretch;
  background: #f9f8fc;
  color: #2C95B7;
`;

const AgendaContent = styled.div`
  box-sizing: border-box;
  padding: 10px 16px;
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const AgendaContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  ${respondTo.m`
    flex-direction: row;
  align-items: center;

  `}
`;

const AgendaDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 0px 16px 16px 16px;
  ${respondTo.m`
    padding: 16px;
  `}
`;

const AgendaTitle = styled.h3`
  color: #060E15;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  padding: 0px;
  margin: 0px;
`;

const AgendaSpeakers = styled.p`
  color: #2C95B7;
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
`;

const Pill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 4px;
  border-radius: 8px;
  background-color: #f9f8fc;
  color: #2C95B7;
  font-family: 'Raleway';
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  white-space: nowrap; // Prevents wrapping
  min-width: 70px; // Ensures all time blocks have the same width
  text-align: center;

  
`;

export const AgendaIcon = styled.img`
  width: ${({ isClock }) => (isClock ? '20px' : '35px')};  // Smaller for clock, larger for others
  height: ${({ isClock }) => (isClock ? '20px' : '35px')};
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  color: #2C95B7;
  stroke: #2C95B7;
`;


export const AgendaIconWrapper = styled.div`
  border: 1px solid #2C95B7;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #2C95B7;
  @media (max-width: 1000px) {
    width: 25px !important; // Slightly smaller icons on mobile
    height: auto !important;
  }
`;
